import { graphql } from '../gql';

export const UPDATE_ONE_PRICE_GROUP_MUTATION = graphql(/* GraphQL */ `
  mutation UpdateOnePriceGroup(
    $data: UpdateOnePriceGroupInput!
    $where: PriceGroupWhereUniqueInput!
  ) {
    updateOnePriceGroup(data: $data, where: $where) {
      id
    }
  }
`);
