import classNames from 'classnames';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
// import {ReactComponent as rarifyLogo} from "../assets/rarify_logo.svg"
function AppHeader() {
  // let windowPathName = window.location.pathname;
  let tempPathname = '';
  if (window.location.pathname.includes('categories')) {
    tempPathname = 'categories';
  } else if (window.location.pathname.includes('material-groups')) {
    tempPathname = 'material-groups';
  } else if (window.location.pathname.includes('pricing')) {
    tempPathname = 'pricing';
  } else if (window.location.pathname.includes('bulk-colors')) {
    tempPathname = 'bulk-colors';
  } else if (window.location.pathname.includes('bulk-products')) {
    tempPathname = 'bulk-products';
  }

  const [pathname, setPathName] = useState(tempPathname);

  return (
    <header className='w-full border-b border-gray-400'>
      <nav className='border-gray-200 bg-white dark:bg-gray-900'>
        <div className='mx-auto flex max-w-screen-2xl flex-wrap items-center justify-start space-x-20  border-gray-500 p-4'>
          <NavLink
            to='/'
            className='flex items-start'
            onClick={() => setPathName('')}
          >
            <img
              src='https://rarify.co/cdn/shop/files/Black_9e73929b-9976-4f91-b282-858fc16cbac6_120x.svg?v=1691888009'
              className='mr-3 h-8'
              alt='Cocoa-dolce Logo'
            />
          </NavLink>
          <ul className='mt-0 flex flex-col rounded-lg border-gray-100 p-0 font-medium dark:border-gray-700 dark:bg-gray-800 md:flex-row md:space-x-8 md:border-0  md:dark:bg-gray-900'>
            <li className='dark:border-gray-700 lg:inline lg:border-b-0'>
              <NavLink
                to='/'
                className={classNames(
                  'flex items-center py-0 w-full  hover:text-primary-600 dark:text-primary-500',
                  pathname === '' ? 'text-primary-600' : 'text-gray-600',
                )}
                aria-current='page'
                onClick={() => setPathName('')}
              >
                Colors
              </NavLink>
            </li>
            <li className='dark:border-gray-700 lg:inline lg:border-b-0'>
              <NavLink
                to='/bulk-colors'
                onClick={() => setPathName('bulk-colors')}
                className={classNames(
                  'flex items-center py-0 w-full  hover:text-primary-600 dark:text-primary-500',
                  pathname === 'bulk-colors'
                    ? 'text-primary-600'
                    : 'text-gray-600',
                )}
                aria-current='page'
              >
                Import Colors
              </NavLink>
            </li>
            <li className='dark:border-gray-700 lg:inline lg:border-b-0'>
              <NavLink
                to='/categories'
                className={classNames(
                  'flex items-center py-0 w-full  hover:text-primary-600 dark:text-primary-500',
                  pathname === 'categories'
                    ? 'text-primary-600'
                    : 'text-gray-600',
                )}
                aria-current='page'
                onClick={() => setPathName('categories')}
              >
                Materials
              </NavLink>
            </li>
            <li className='dark:border-gray-700 lg:inline '>
              <NavLink
                to='/material-groups'
                onClick={() => setPathName('material-groups')}
                className={classNames(
                  'flex items-center py-0 w-full  hover:text-primary-600 dark:text-primary-500',
                  pathname === 'material-groups'
                    ? 'text-primary-600'
                    : 'text-gray-600',
                )}
                aria-current='page'
              >
                Price Groups
              </NavLink>
            </li>
            <li className='dark:border-gray-700 lg:inline lg:border-b-0'>
              <NavLink
                to='/bulk-products'
                onClick={() => setPathName('bulk-products')}
                className={classNames(
                  'flex items-center py-0 w-full  hover:text-primary-600 dark:text-primary-500',
                  pathname === 'bulk-products'
                    ? 'text-primary-600'
                    : 'text-gray-600',
                )}
                aria-current='page'
              >
                Import Product Options
              </NavLink>
            </li>
            <li className='dark:border-gray-700 lg:inline lg:border-b-0'>
              <NavLink
                to='/pricing'
                onClick={() => setPathName('pricing')}
                className={classNames(
                  'flex items-center py-0 w-full  hover:text-primary-600 dark:text-primary-500',
                  pathname === 'pricing' ? 'text-primary-600' : 'text-gray-600',
                )}
                aria-current='page'
              >
                Pricing
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
}

export default AppHeader;
