/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    query ShopifyProducts(\n      $first: Int\n      $reverse: Boolean\n      $last: Int\n      $before: String\n      $after: String\n      $query: String\n    ) {\n      shopifyProducts(\n        first: $first\n        reverse: $reverse\n        last: $last\n        before: $before\n        after: $after\n        query: $query\n      ) {\n        pageInfo {\n          endCursor\n          hasNextPage\n          startCursor\n          hasPreviousPage\n        }\n        queryResult\n      }\n    }\n  ": types.ShopifyProductsDocument,
    "\n    query MaterialsWithCategories(\n      $take: Int\n      $skip: Int\n      $where: MaterialWhereInput\n      $orderBy: [MaterialOrderByWithRelationInput!]\n    ) {\n      materials(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {\n        title\n        previewImageUrl\n        previewImageId\n        metaObjectId\n        id\n        Category {\n          title\n          id\n        }\n      }\n    }\n  ": types.MaterialsWithCategoriesDocument,
    "\n    query Categories($where: CategoryWhereInput) {\n      categories(where: $where) {\n        title\n        id\n      }\n    }\n  ": types.CategoriesDocument,
    "\n      query CategoriesWithMaterials {\n        categories {\n          materials {\n            id\n            title\n            previewImageUrl\n          }\n          title\n          url\n          id\n          designer\n          description\n          composition\n        }\n      }\n    ": types.CategoriesWithMaterialsDocument,
    "\n    query AllCategoriesByPage(\n      $where: CategoryWhereInput\n      $take: Int\n      $skip: Int\n      $orderBy: [CategoryOrderByWithRelationInput!]\n    ) {\n      categories(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {\n        composition\n        description\n        designer\n        id\n        materials {\n          id\n          previewImageUrl\n          title\n        }\n        title\n        url\n      }\n    }\n  ": types.AllCategoriesByPageDocument,
    "\n    query AggregateMaterial($where: MaterialWhereInput) {\n      aggregateMaterial(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  ": types.AggregateMaterialDocument,
    "\n    mutation CreateOneMaterial($data: MaterialCreateWithShopifyInput!) {\n      createOneMaterial(data: $data) {\n        id\n        previewImageUrl\n        categoryId\n        title\n      }\n    }\n  ": types.CreateOneMaterialDocument,
    "\n    mutation UpdateMaterialWithShopifyDefinition(\n      $data: MaterialUpdateWithShopifyDefinitionInput!\n      $where: MaterialWhereUniqueInput!\n    ) {\n      updateOneMaterial(data: $data, where: $where) {\n        id\n        previewImageUrl\n        title\n        categoryId\n      }\n    }\n  ": types.UpdateMaterialWithShopifyDefinitionDocument,
    "\n    mutation DeleteOneMaterial($where: MaterialWhereUniqueInput!) {\n      deleteOneMaterial(where: $where) {\n        id\n      }\n    }\n  ": types.DeleteOneMaterialDocument,
    "\n    mutation CreateOneCategory($data: CategoryCreateInput!) {\n      createOneCategory(data: $data) {\n        id\n        title\n        url\n      }\n    }\n  ": types.CreateOneCategoryDocument,
    "\n    mutation UpdateOneCategory(\n      $data: CategoryUpdateInput!\n      $where: CategoryWhereUniqueInput!\n    ) {\n      updateOneCategory(data: $data, where: $where) {\n        title\n        url\n        composition\n        designer\n        materials {\n          id\n          previewImageUrl\n          title\n        }\n      }\n    }\n  ": types.UpdateOneCategoryDocument,
    "\n      query Category($where: CategoryWhereUniqueInput!) {\n        category(where: $where) {\n          _count {\n            PriceSubGroup\n            materials\n          }\n        }\n      }\n    ": types.CategoryDocument,
    "\n    mutation DeleteOneCategory($where: CategoryWhereUniqueInput!) {\n      deleteOneCategory(where: $where) {\n        id\n        title\n      }\n    }\n  ": types.DeleteOneCategoryDocument,
    "\n    mutation DeleteOnePriceSubGroup($where: PriceSubGroupWhereUniqueInput!) {\n      deleteOnePriceSubGroup(where: $where) {\n        id\n        title\n      }\n    }\n  ": types.DeleteOnePriceSubGroupDocument,
    "\n    query AggregateCategory($where: CategoryWhereInput) {\n      aggregateCategory(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  ": types.AggregateCategoryDocument,
    "\n    query _count($where: MaterialImportSheetRowWhereInput) {\n      aggregateMaterialImportSheetRow(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  ": types._CountDocument,
    "\n    query AggregateProductOptionsImportSheetSuccessfully(\n      $where: ProductOptionsImportSheetWhereInput\n    ) {\n      aggregateProductOptionsImportSheet(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  ": types.AggregateProductOptionsImportSheetSuccessfullyDocument,
    "\n    query AggregateMaterialImportSheetRow {\n      aggregateMaterialImportSheetRow {\n        _count {\n          _all\n        }\n      }\n    }\n  ": types.AggregateMaterialImportSheetRowDocument,
    "\n    query AggregateProductOptionsImportSheet {\n      aggregateProductOptionsImportSheet {\n        _count {\n          _all\n        }\n      }\n    }\n  ": types.AggregateProductOptionsImportSheetDocument,
    "\n    query MaterialImportSheetRows(\n      $skip: Int\n      $take: Int\n      $orderBy: [MaterialImportSheetRowOrderByWithRelationInput!]\n    ) {\n      materialImportSheetRows(skip: $skip, take: $take, orderBy: $orderBy) {\n        id\n        message\n        status\n        data\n        associatedMaterial {\n          previewImageUrl\n          previewImageId\n          title\n        }\n      }\n    }\n  ": types.MaterialImportSheetRowsDocument,
    "\n    query ProductOptionsImportSheets(\n      $orderBy: [ProductOptionsImportSheetOrderByWithRelationInput!]\n      $take: Int\n      $skip: Int\n    ) {\n      productOptionsImportSheets(orderBy: $orderBy, take: $take, skip: $skip) {\n        id\n        status\n        resultFile\n      }\n    }\n  ": types.ProductOptionsImportSheetsDocument,
    "\n    query Materials($where: MaterialWhereInput) {\n      materials(where: $where) {\n        id\n        title\n        previewImageUrl\n      }\n    }\n  ": types.MaterialsDocument,
    "\n    mutation DeleteOneMaterialImportSheetRow(\n      $where: MaterialImportSheetRowWhereUniqueInput!\n    ) {\n      deleteOneMaterialImportSheetRow(where: $where) {\n        id\n        data\n      }\n    }\n  ": types.DeleteOneMaterialImportSheetRowDocument,
    "\n    query MaterialsForPriceSubGroupMutation {\n      materials {\n        id\n        previewImageUrl\n      }\n    }\n  ": types.MaterialsForPriceSubGroupMutationDocument,
    "\n    mutation UpdateProductVariantPrice(\n      $data: [UpdateProductVariantPriceInput!]!\n    ) {\n      updateProductVariantPrice(data: $data) {\n        compareAtPrice\n        id\n        price\n      }\n    }\n  ": types.UpdateProductVariantPriceDocument,
    "\n    query Products($where: ProductWhereInput) {\n      products(where: $where) {\n        adminGraphQlId\n        variantOptions {\n          variantOptionName\n          id\n          priceGroups {\n            variantOptionValue\n            id\n            priceSubGroups {\n              url\n              title\n              description\n              id\n              designer\n              composition\n              category {\n                materials {\n                  id\n                  previewImageUrl\n                  title\n                }\n              }\n              materials {\n                id\n                previewImageUrl\n                title\n              }\n            }\n          }\n        }\n      }\n    }\n  ": types.ProductsDocument,
    "\n    query priceGroupCount($where: PriceGroupWhereInput) {\n      aggregatePriceGroup(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  ": types.PriceGroupCountDocument,
    "\n    mutation UpdateOnePriceSubGroup(\n      $data: PriceSubGroupUpdateWithShopifyInput!\n      $where: PriceSubGroupWhereUniqueIdInput!\n    ) {\n      updateOnePriceSubGroup(data: $data, where: $where) {\n        id\n        materialIds\n        designer\n        description\n        title\n        url\n      }\n    }\n  ": types.UpdateOnePriceSubGroupDocument,
    "\n        mutation CreateOneVariantOption(\n          $data: CreateVariantOptionWithShopifyDefinitionInput!\n        ) {\n          createOneVariantOption(data: $data) {\n            id\n          }\n        }\n      ": types.CreateOneVariantOptionDocument,
    "\n        mutation CreateOnePriceSubGroup(\n          $data: PriceSubGroupCreateWithShopifyInput!\n        ) {\n          createOnePriceSubGroup(data: $data) {\n            id\n            title\n          }\n        }\n      ": types.CreateOnePriceSubGroupDocument,
    "\n        mutation UpdateOneVariantOption(\n          $data: UpdateOneVariantOptionInput!\n          $where: VariantOptionWhereUniqueIdInput!\n        ) {\n          updateOneVariantOption(data: $data, where: $where) {\n            id\n          }\n        }\n      ": types.UpdateOneVariantOptionDocument,
    "\n    mutation CreateOnePriceGroup(\n      $data: CreateOnePriceGroupWithShopifyDefinitionInput!\n    ) {\n      createOnePriceGroup(data: $data) {\n        id\n      }\n    }\n  ": types.CreateOnePriceGroupDocument,
    "\n      mutation UpdateOneVariantOption(\n        $data: UpdateOneVariantOptionInput!\n        $where: VariantOptionWhereUniqueIdInput!\n      ) {\n        updateOneVariantOption(data: $data, where: $where) {\n          id\n        }\n      }\n    ": types.UpdateOneVariantOptionDocument,
    "\n  mutation UpdateOnePriceGroup(\n    $data: UpdateOnePriceGroupInput!\n    $where: PriceGroupWhereUniqueInput!\n  ) {\n    updateOnePriceGroup(data: $data, where: $where) {\n      id\n    }\n  }\n": types.UpdateOnePriceGroupDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query ShopifyProducts(\n      $first: Int\n      $reverse: Boolean\n      $last: Int\n      $before: String\n      $after: String\n      $query: String\n    ) {\n      shopifyProducts(\n        first: $first\n        reverse: $reverse\n        last: $last\n        before: $before\n        after: $after\n        query: $query\n      ) {\n        pageInfo {\n          endCursor\n          hasNextPage\n          startCursor\n          hasPreviousPage\n        }\n        queryResult\n      }\n    }\n  "): (typeof documents)["\n    query ShopifyProducts(\n      $first: Int\n      $reverse: Boolean\n      $last: Int\n      $before: String\n      $after: String\n      $query: String\n    ) {\n      shopifyProducts(\n        first: $first\n        reverse: $reverse\n        last: $last\n        before: $before\n        after: $after\n        query: $query\n      ) {\n        pageInfo {\n          endCursor\n          hasNextPage\n          startCursor\n          hasPreviousPage\n        }\n        queryResult\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query MaterialsWithCategories(\n      $take: Int\n      $skip: Int\n      $where: MaterialWhereInput\n      $orderBy: [MaterialOrderByWithRelationInput!]\n    ) {\n      materials(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {\n        title\n        previewImageUrl\n        previewImageId\n        metaObjectId\n        id\n        Category {\n          title\n          id\n        }\n      }\n    }\n  "): (typeof documents)["\n    query MaterialsWithCategories(\n      $take: Int\n      $skip: Int\n      $where: MaterialWhereInput\n      $orderBy: [MaterialOrderByWithRelationInput!]\n    ) {\n      materials(take: $take, skip: $skip, where: $where, orderBy: $orderBy) {\n        title\n        previewImageUrl\n        previewImageId\n        metaObjectId\n        id\n        Category {\n          title\n          id\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Categories($where: CategoryWhereInput) {\n      categories(where: $where) {\n        title\n        id\n      }\n    }\n  "): (typeof documents)["\n    query Categories($where: CategoryWhereInput) {\n      categories(where: $where) {\n        title\n        id\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query CategoriesWithMaterials {\n        categories {\n          materials {\n            id\n            title\n            previewImageUrl\n          }\n          title\n          url\n          id\n          designer\n          description\n          composition\n        }\n      }\n    "): (typeof documents)["\n      query CategoriesWithMaterials {\n        categories {\n          materials {\n            id\n            title\n            previewImageUrl\n          }\n          title\n          url\n          id\n          designer\n          description\n          composition\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AllCategoriesByPage(\n      $where: CategoryWhereInput\n      $take: Int\n      $skip: Int\n      $orderBy: [CategoryOrderByWithRelationInput!]\n    ) {\n      categories(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {\n        composition\n        description\n        designer\n        id\n        materials {\n          id\n          previewImageUrl\n          title\n        }\n        title\n        url\n      }\n    }\n  "): (typeof documents)["\n    query AllCategoriesByPage(\n      $where: CategoryWhereInput\n      $take: Int\n      $skip: Int\n      $orderBy: [CategoryOrderByWithRelationInput!]\n    ) {\n      categories(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {\n        composition\n        description\n        designer\n        id\n        materials {\n          id\n          previewImageUrl\n          title\n        }\n        title\n        url\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AggregateMaterial($where: MaterialWhereInput) {\n      aggregateMaterial(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  "): (typeof documents)["\n    query AggregateMaterial($where: MaterialWhereInput) {\n      aggregateMaterial(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CreateOneMaterial($data: MaterialCreateWithShopifyInput!) {\n      createOneMaterial(data: $data) {\n        id\n        previewImageUrl\n        categoryId\n        title\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateOneMaterial($data: MaterialCreateWithShopifyInput!) {\n      createOneMaterial(data: $data) {\n        id\n        previewImageUrl\n        categoryId\n        title\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UpdateMaterialWithShopifyDefinition(\n      $data: MaterialUpdateWithShopifyDefinitionInput!\n      $where: MaterialWhereUniqueInput!\n    ) {\n      updateOneMaterial(data: $data, where: $where) {\n        id\n        previewImageUrl\n        title\n        categoryId\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateMaterialWithShopifyDefinition(\n      $data: MaterialUpdateWithShopifyDefinitionInput!\n      $where: MaterialWhereUniqueInput!\n    ) {\n      updateOneMaterial(data: $data, where: $where) {\n        id\n        previewImageUrl\n        title\n        categoryId\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation DeleteOneMaterial($where: MaterialWhereUniqueInput!) {\n      deleteOneMaterial(where: $where) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation DeleteOneMaterial($where: MaterialWhereUniqueInput!) {\n      deleteOneMaterial(where: $where) {\n        id\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CreateOneCategory($data: CategoryCreateInput!) {\n      createOneCategory(data: $data) {\n        id\n        title\n        url\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateOneCategory($data: CategoryCreateInput!) {\n      createOneCategory(data: $data) {\n        id\n        title\n        url\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UpdateOneCategory(\n      $data: CategoryUpdateInput!\n      $where: CategoryWhereUniqueInput!\n    ) {\n      updateOneCategory(data: $data, where: $where) {\n        title\n        url\n        composition\n        designer\n        materials {\n          id\n          previewImageUrl\n          title\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateOneCategory(\n      $data: CategoryUpdateInput!\n      $where: CategoryWhereUniqueInput!\n    ) {\n      updateOneCategory(data: $data, where: $where) {\n        title\n        url\n        composition\n        designer\n        materials {\n          id\n          previewImageUrl\n          title\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      query Category($where: CategoryWhereUniqueInput!) {\n        category(where: $where) {\n          _count {\n            PriceSubGroup\n            materials\n          }\n        }\n      }\n    "): (typeof documents)["\n      query Category($where: CategoryWhereUniqueInput!) {\n        category(where: $where) {\n          _count {\n            PriceSubGroup\n            materials\n          }\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation DeleteOneCategory($where: CategoryWhereUniqueInput!) {\n      deleteOneCategory(where: $where) {\n        id\n        title\n      }\n    }\n  "): (typeof documents)["\n    mutation DeleteOneCategory($where: CategoryWhereUniqueInput!) {\n      deleteOneCategory(where: $where) {\n        id\n        title\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation DeleteOnePriceSubGroup($where: PriceSubGroupWhereUniqueInput!) {\n      deleteOnePriceSubGroup(where: $where) {\n        id\n        title\n      }\n    }\n  "): (typeof documents)["\n    mutation DeleteOnePriceSubGroup($where: PriceSubGroupWhereUniqueInput!) {\n      deleteOnePriceSubGroup(where: $where) {\n        id\n        title\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AggregateCategory($where: CategoryWhereInput) {\n      aggregateCategory(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  "): (typeof documents)["\n    query AggregateCategory($where: CategoryWhereInput) {\n      aggregateCategory(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query _count($where: MaterialImportSheetRowWhereInput) {\n      aggregateMaterialImportSheetRow(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  "): (typeof documents)["\n    query _count($where: MaterialImportSheetRowWhereInput) {\n      aggregateMaterialImportSheetRow(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AggregateProductOptionsImportSheetSuccessfully(\n      $where: ProductOptionsImportSheetWhereInput\n    ) {\n      aggregateProductOptionsImportSheet(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  "): (typeof documents)["\n    query AggregateProductOptionsImportSheetSuccessfully(\n      $where: ProductOptionsImportSheetWhereInput\n    ) {\n      aggregateProductOptionsImportSheet(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AggregateMaterialImportSheetRow {\n      aggregateMaterialImportSheetRow {\n        _count {\n          _all\n        }\n      }\n    }\n  "): (typeof documents)["\n    query AggregateMaterialImportSheetRow {\n      aggregateMaterialImportSheetRow {\n        _count {\n          _all\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query AggregateProductOptionsImportSheet {\n      aggregateProductOptionsImportSheet {\n        _count {\n          _all\n        }\n      }\n    }\n  "): (typeof documents)["\n    query AggregateProductOptionsImportSheet {\n      aggregateProductOptionsImportSheet {\n        _count {\n          _all\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query MaterialImportSheetRows(\n      $skip: Int\n      $take: Int\n      $orderBy: [MaterialImportSheetRowOrderByWithRelationInput!]\n    ) {\n      materialImportSheetRows(skip: $skip, take: $take, orderBy: $orderBy) {\n        id\n        message\n        status\n        data\n        associatedMaterial {\n          previewImageUrl\n          previewImageId\n          title\n        }\n      }\n    }\n  "): (typeof documents)["\n    query MaterialImportSheetRows(\n      $skip: Int\n      $take: Int\n      $orderBy: [MaterialImportSheetRowOrderByWithRelationInput!]\n    ) {\n      materialImportSheetRows(skip: $skip, take: $take, orderBy: $orderBy) {\n        id\n        message\n        status\n        data\n        associatedMaterial {\n          previewImageUrl\n          previewImageId\n          title\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query ProductOptionsImportSheets(\n      $orderBy: [ProductOptionsImportSheetOrderByWithRelationInput!]\n      $take: Int\n      $skip: Int\n    ) {\n      productOptionsImportSheets(orderBy: $orderBy, take: $take, skip: $skip) {\n        id\n        status\n        resultFile\n      }\n    }\n  "): (typeof documents)["\n    query ProductOptionsImportSheets(\n      $orderBy: [ProductOptionsImportSheetOrderByWithRelationInput!]\n      $take: Int\n      $skip: Int\n    ) {\n      productOptionsImportSheets(orderBy: $orderBy, take: $take, skip: $skip) {\n        id\n        status\n        resultFile\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Materials($where: MaterialWhereInput) {\n      materials(where: $where) {\n        id\n        title\n        previewImageUrl\n      }\n    }\n  "): (typeof documents)["\n    query Materials($where: MaterialWhereInput) {\n      materials(where: $where) {\n        id\n        title\n        previewImageUrl\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation DeleteOneMaterialImportSheetRow(\n      $where: MaterialImportSheetRowWhereUniqueInput!\n    ) {\n      deleteOneMaterialImportSheetRow(where: $where) {\n        id\n        data\n      }\n    }\n  "): (typeof documents)["\n    mutation DeleteOneMaterialImportSheetRow(\n      $where: MaterialImportSheetRowWhereUniqueInput!\n    ) {\n      deleteOneMaterialImportSheetRow(where: $where) {\n        id\n        data\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query MaterialsForPriceSubGroupMutation {\n      materials {\n        id\n        previewImageUrl\n      }\n    }\n  "): (typeof documents)["\n    query MaterialsForPriceSubGroupMutation {\n      materials {\n        id\n        previewImageUrl\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UpdateProductVariantPrice(\n      $data: [UpdateProductVariantPriceInput!]!\n    ) {\n      updateProductVariantPrice(data: $data) {\n        compareAtPrice\n        id\n        price\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateProductVariantPrice(\n      $data: [UpdateProductVariantPriceInput!]!\n    ) {\n      updateProductVariantPrice(data: $data) {\n        compareAtPrice\n        id\n        price\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Products($where: ProductWhereInput) {\n      products(where: $where) {\n        adminGraphQlId\n        variantOptions {\n          variantOptionName\n          id\n          priceGroups {\n            variantOptionValue\n            id\n            priceSubGroups {\n              url\n              title\n              description\n              id\n              designer\n              composition\n              category {\n                materials {\n                  id\n                  previewImageUrl\n                  title\n                }\n              }\n              materials {\n                id\n                previewImageUrl\n                title\n              }\n            }\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    query Products($where: ProductWhereInput) {\n      products(where: $where) {\n        adminGraphQlId\n        variantOptions {\n          variantOptionName\n          id\n          priceGroups {\n            variantOptionValue\n            id\n            priceSubGroups {\n              url\n              title\n              description\n              id\n              designer\n              composition\n              category {\n                materials {\n                  id\n                  previewImageUrl\n                  title\n                }\n              }\n              materials {\n                id\n                previewImageUrl\n                title\n              }\n            }\n          }\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query priceGroupCount($where: PriceGroupWhereInput) {\n      aggregatePriceGroup(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  "): (typeof documents)["\n    query priceGroupCount($where: PriceGroupWhereInput) {\n      aggregatePriceGroup(where: $where) {\n        _count {\n          _all\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UpdateOnePriceSubGroup(\n      $data: PriceSubGroupUpdateWithShopifyInput!\n      $where: PriceSubGroupWhereUniqueIdInput!\n    ) {\n      updateOnePriceSubGroup(data: $data, where: $where) {\n        id\n        materialIds\n        designer\n        description\n        title\n        url\n      }\n    }\n  "): (typeof documents)["\n    mutation UpdateOnePriceSubGroup(\n      $data: PriceSubGroupUpdateWithShopifyInput!\n      $where: PriceSubGroupWhereUniqueIdInput!\n    ) {\n      updateOnePriceSubGroup(data: $data, where: $where) {\n        id\n        materialIds\n        designer\n        description\n        title\n        url\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        mutation CreateOneVariantOption(\n          $data: CreateVariantOptionWithShopifyDefinitionInput!\n        ) {\n          createOneVariantOption(data: $data) {\n            id\n          }\n        }\n      "): (typeof documents)["\n        mutation CreateOneVariantOption(\n          $data: CreateVariantOptionWithShopifyDefinitionInput!\n        ) {\n          createOneVariantOption(data: $data) {\n            id\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        mutation CreateOnePriceSubGroup(\n          $data: PriceSubGroupCreateWithShopifyInput!\n        ) {\n          createOnePriceSubGroup(data: $data) {\n            id\n            title\n          }\n        }\n      "): (typeof documents)["\n        mutation CreateOnePriceSubGroup(\n          $data: PriceSubGroupCreateWithShopifyInput!\n        ) {\n          createOnePriceSubGroup(data: $data) {\n            id\n            title\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        mutation UpdateOneVariantOption(\n          $data: UpdateOneVariantOptionInput!\n          $where: VariantOptionWhereUniqueIdInput!\n        ) {\n          updateOneVariantOption(data: $data, where: $where) {\n            id\n          }\n        }\n      "): (typeof documents)["\n        mutation UpdateOneVariantOption(\n          $data: UpdateOneVariantOptionInput!\n          $where: VariantOptionWhereUniqueIdInput!\n        ) {\n          updateOneVariantOption(data: $data, where: $where) {\n            id\n          }\n        }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CreateOnePriceGroup(\n      $data: CreateOnePriceGroupWithShopifyDefinitionInput!\n    ) {\n      createOnePriceGroup(data: $data) {\n        id\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateOnePriceGroup(\n      $data: CreateOnePriceGroupWithShopifyDefinitionInput!\n    ) {\n      createOnePriceGroup(data: $data) {\n        id\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n      mutation UpdateOneVariantOption(\n        $data: UpdateOneVariantOptionInput!\n        $where: VariantOptionWhereUniqueIdInput!\n      ) {\n        updateOneVariantOption(data: $data, where: $where) {\n          id\n        }\n      }\n    "): (typeof documents)["\n      mutation UpdateOneVariantOption(\n        $data: UpdateOneVariantOptionInput!\n        $where: VariantOptionWhereUniqueIdInput!\n      ) {\n        updateOneVariantOption(data: $data, where: $where) {\n          id\n        }\n      }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateOnePriceGroup(\n    $data: UpdateOnePriceGroupInput!\n    $where: PriceGroupWhereUniqueInput!\n  ) {\n    updateOnePriceGroup(data: $data, where: $where) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateOnePriceGroup(\n    $data: UpdateOnePriceGroupInput!\n    $where: PriceGroupWhereUniqueInput!\n  ) {\n    updateOnePriceGroup(data: $data, where: $where) {\n      id\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;