import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { useState } from 'react';
import DashboardPage from './pages/Dashboard/SuspendedDashboardPage';
import AppHeader from './components/AppHeader';
import MaterialGroupsPage from './pages/MaterialGroups/SuspendedMaterialGroups';
import PricingPage from './pages/PricingPage/SuspendedPricingPage';
import CategoriesPage from './pages/Categories/SuspendedCategoriesPage';
import BulkImportMaterialsPage from './pages/Bulk-import/SuspendedBulkImportPage';
import BulkImportProductsPage from './pages/Bulk-import-products/SuspendedBulkImportProductPage';

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: Infinity,
            useErrorBoundary: true,
            suspense: true,
          },
          mutations: {
            useErrorBoundary: true,
          },
        },
      }),
  );
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ToastContainer
          bodyClassName='successToast'
          position='top-center'
          stacked
          hideProgressBar
          autoClose={3000}
          newestOnTop
          theme='light'
        />
        <AppHeader />
        <div className='min-h-screen w-full overflow-x-scroll bg-gray-50 p-4'>
          <Routes>
            <Route index element={<DashboardPage />} />
            <Route path='/material-groups' element={<MaterialGroupsPage />} />
            <Route path='/pricing' element={<PricingPage />} />
            <Route path='/categories' element={<CategoriesPage />} />
            <Route path='/bulk-colors' element={<BulkImportMaterialsPage />} />
            <Route path='/bulk-products' element={<BulkImportProductsPage />} />
          </Routes>
        </div>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
