import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'flowbite/dist/flowbite';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { updateAxiosAuthToken } from './services/api.services';

if (process.env.REACT_APP_ENV !== 'local') {
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.has('jwt')
    ? searchParams.get('jwt')
    : window.localStorage?.getItem('jwt');
  if (token) {
    updateAxiosAuthToken(token);
  }
}
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
