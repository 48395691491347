import { Suspense, lazy } from 'react';
import PageErrorBoundary from '../../components/PageErrorBoundary';
import Loading from '../../components/Loading';

const DashboardPage = lazy(() => import('./Index'));
function SuspendedDashboardPage(props: any) {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<Loading />}>
        <DashboardPage {...props} />
      </Suspense>
    </PageErrorBoundary>
  );
}

export default SuspendedDashboardPage;
