import { lazy, Suspense } from 'react';
import PageErrorBoundary from '../../components/PageErrorBoundary';
import Loading from '../../components/Loading';

const MaterialGroupsPage = lazy(() => import('./Index'));

function SuspendedMaterialGroupsPage(props: any) {
  return (
    <PageErrorBoundary>
      <Suspense fallback={<Loading />}>
        <MaterialGroupsPage {...props} />
      </Suspense>
    </PageErrorBoundary>
  );
}

export default SuspendedMaterialGroupsPage;
