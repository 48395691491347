export const materialsData = {
  totalCount: 280,
  data: [
    {
      id: 5256028553258,
      order_no: '#1281',
      customer_name: 'Monica Castillo',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-31T18:50:57.816Z',
      total: '3388.66',
      total_line_items: 18,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5256028553258',
    },
    {
      id: 5255933788202,
      order_no: '#1280',
      customer_name: 'Heather Robinson',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-31T17:40:54.572Z',
      total: '854.92',
      total_line_items: 6,
      total_individual_order: 27,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5255933788202',
    },
    {
      id: 5255851802666,
      order_no: '#1279',
      customer_name: 'Ben Voegeli',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-31T16:35:53.292Z',
      total: '21.89',
      total_line_items: 2,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5255851802666',
    },
    {
      id: 5255798489130,
      order_no: '#1278',
      customer_name: 'Colleen McDonough',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-31T15:54:45.942Z',
      total: '3075.68',
      total_line_items: 2,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5255798489130',
    },
    {
      id: 5255707983914,
      order_no: '#1277',
      customer_name: 'Lora Wiley',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-31T14:44:27.036Z',
      total: '1170.65',
      total_line_items: 23,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5255707983914',
    },
    {
      id: 5255652081706,
      order_no: '#1276',
      customer_name: 'Lora Wiley',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-31T13:49:55.881Z',
      total: '1.70',
      total_line_items: 2,
      total_individual_order: 1,
      fullfillment_status: false,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5255652081706',
    },
    {
      id: 5254770229290,
      order_no: '#1275',
      customer_name: 'Mary E. Shannon',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-30T21:11:33.940Z',
      total: '117.50',
      total_line_items: 8,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5254770229290',
    },
    {
      id: 5254762102826,
      order_no: '#1274',
      customer_name: 'Allison Mikolajczyk',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-30T21:04:07.099Z',
      total: '878.55',
      total_line_items: 6,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5254762102826',
    },
    {
      id: 5254754598954,
      order_no: '#1273',
      customer_name: 'Allison Mikolajczyk',
      individualOrderCreated: 'pending',
      fileUploaded: 'pending',
      created_on: '2024-05-30T20:57:30.144Z',
      total: '870.00',
      total_line_items: 5,
      total_individual_order: 0,
      fullfillment_status: false,
      payment_status: false,
      order_approved: false,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5254754598954',
    },
    {
      id: 5254737920042,
      order_no: '#1272',
      customer_name: 'Allison Mikolajczyk',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-30T20:43:58.060Z',
      total: '877.65',
      total_line_items: 6,
      total_individual_order: 1,
      fullfillment_status: false,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5254737920042',
    },
    {
      id: 5254718160938,
      order_no: '#1271',
      customer_name: 'Doug Brehm',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-30T20:27:17.294Z',
      total: '83.56',
      total_line_items: 3,
      total_individual_order: 2,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5254718160938',
    },
    {
      id: 5254676447274,
      order_no: '#1270',
      customer_name: 'Jenn Hamilton',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-30T19:56:13.496Z',
      total: '2858.76',
      total_line_items: 10,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5254676447274',
    },
    {
      id: 5254661210154,
      order_no: '#1269',
      customer_name: 'Doug Brehm',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-30T19:49:20.015Z',
      total: '28.50',
      total_line_items: 2,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5254661210154',
    },
    {
      id: 5254577225770,
      order_no: '#1268',
      customer_name: 'Tracy Cochran',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-30T18:50:54.263Z',
      total: '992.28',
      total_line_items: 13,
      total_individual_order: 9,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5254577225770',
    },
    {
      id: 5254462865450,
      order_no: '#1267',
      customer_name: 'Lora Wiley',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-30T17:26:57.446Z',
      total: '1170.65',
      total_line_items: 23,
      total_individual_order: 1,
      fullfillment_status: false,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5254462865450',
    },
    {
      id: 5254385041450,
      order_no: '#1266',
      customer_name: 'Jenny Park',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-30T16:28:50.318Z',
      total: '1158.49',
      total_line_items: 4,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5254385041450',
    },
    {
      id: 5254372884522,
      order_no: '#1265',
      customer_name: 'Johana Fowler',
      individualOrderCreated: 'pending',
      fileUploaded: 'inprogress',
      created_on: '2024-05-30T16:21:53.064Z',
      total: '105.12',
      total_line_items: 3,
      total_individual_order: 0,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5254372884522',
    },
    {
      id: 5254343098410,
      order_no: '#1264',
      customer_name: 'Miranda Bales',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-30T15:59:21.070Z',
      total: '261.84',
      total_line_items: 4,
      total_individual_order: 2,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5254343098410',
    },
    {
      id: 5254340771882,
      order_no: '#1263',
      customer_name: 'Dawn Howe',
      individualOrderCreated: 'pending',
      fileUploaded: 'done',
      created_on: '2024-05-30T15:57:18.112Z',
      total: '22.50',
      total_line_items: 2,
      total_individual_order: 0,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5254340771882',
    },
    {
      id: 5254327566378,
      order_no: '#1262',
      customer_name: 'Dawn Howe',
      individualOrderCreated: 'pending',
      fileUploaded: 'pending',
      created_on: '2024-05-30T15:44:09.792Z',
      total: '12.50',
      total_line_items: 1,
      total_individual_order: 0,
      fullfillment_status: false,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5254327566378',
    },
    {
      id: 5251069509674,
      order_no: '#1261',
      customer_name: 'Johana Fowler',
      individualOrderCreated: 'pending',
      fileUploaded: 'done',
      created_on: '2024-05-28T16:15:52.377Z',
      total: '108.29',
      total_line_items: 4,
      total_individual_order: 0,
      fullfillment_status: false,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5251069509674',
    },
    {
      id: 5251025502250,
      order_no: '#1260',
      customer_name: 'Dawn Howe',
      individualOrderCreated: 'pending',
      fileUploaded: 'pending',
      created_on: '2024-05-28T15:35:04.434Z',
      total: '22.50',
      total_line_items: 1,
      total_individual_order: 0,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5251025502250',
    },
    {
      id: 5242899005482,
      order_no: '#1259',
      customer_name: 'Erica Stephens',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-23T20:38:39.276Z',
      total: '847.20',
      total_line_items: 3,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5242899005482',
    },
    {
      id: 5242822459434,
      order_no: '#1258',
      customer_name: 'Jenny Park',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-23T19:32:36.858Z',
      total: '1149.11',
      total_line_items: 4,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5242822459434',
    },
    {
      id: 5242806829098,
      order_no: '#1257',
      customer_name: 'Jenny Park',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-23T19:18:13.702Z',
      total: '1124.99',
      total_line_items: 4,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5242806829098',
    },
    {
      id: 5242575650858,
      order_no: '#1256',
      customer_name: 'Russell Yost',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-23T16:01:27.293Z',
      total: '67.47',
      total_line_items: 4,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5242575650858',
    },
    {
      id: 5242567721002,
      order_no: '#1255',
      customer_name: 'Heidi Taphorn',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-23T15:54:22.850Z',
      total: '26.53',
      total_line_items: 2,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5242567721002',
    },
    {
      id: 5242552909866,
      order_no: '#1254',
      customer_name: 'Anne Root',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-23T15:40:48.041Z',
      total: '455.01',
      total_line_items: 9,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5242552909866',
    },
    {
      id: 5242539442218,
      order_no: '#1253',
      customer_name: 'Anne Root',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-23T15:28:37.717Z',
      total: '455.00',
      total_line_items: 9,
      total_individual_order: 8,
      fullfillment_status: false,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5242539442218',
    },
    {
      id: 5242508312618,
      order_no: '#1252',
      customer_name: 'Anne Root',
      individualOrderCreated: 'pending',
      fileUploaded: 'pending',
      created_on: '2024-05-23T15:00:43.400Z',
      total: '441.75',
      total_line_items: 8,
      total_individual_order: 0,
      fullfillment_status: false,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5242508312618',
    },
    {
      id: 5242484457514,
      order_no: '#1251',
      customer_name: 'Karla Cornelius',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-23T14:37:38.535Z',
      total: '939.19',
      total_line_items: 7,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5242484457514',
    },
    {
      id: 5240334647338,
      order_no: '#1250',
      customer_name: 'Trevin Smith',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-21T20:07:49.919Z',
      total: '399.13',
      total_line_items: 3,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5240334647338',
    },
    {
      id: 5240279629866,
      order_no: '#1249',
      customer_name: 'Lauren Brown',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-21T19:18:10.659Z',
      total: '57.94',
      total_line_items: 2,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5240279629866',
    },
    {
      id: 5240264491050,
      order_no: '#1248',
      customer_name: 'Alli Sacket',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-21T19:04:46.150Z',
      total: '1733.00',
      total_line_items: 3,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5240264491050',
    },
    {
      id: 5240253775914,
      order_no: '#1247',
      customer_name: 'Alli Sacket',
      individualOrderCreated: 'pending',
      fileUploaded: 'pending',
      created_on: '2024-05-21T18:54:01.242Z',
      total: '1710.00',
      total_line_items: 2,
      total_individual_order: 0,
      fullfillment_status: false,
      payment_status: false,
      order_approved: false,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5240253775914',
    },
    {
      id: 5240247713834,
      order_no: '#1246',
      customer_name: 'Blakely Bittel',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-21T18:48:24.837Z',
      total: '137.51',
      total_line_items: 2,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5240247713834',
    },
    {
      id: 5239050895402,
      order_no: '#1245',
      customer_name: 'Amy Schertz',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-20T19:03:44.412Z',
      total: '309.00',
      total_line_items: 2,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5239050895402',
    },
    {
      id: 5239031070762,
      order_no: '#1244',
      customer_name: 'Aidan Packer',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-20T18:44:35.849Z',
      total: '323.82',
      total_line_items: 2,
      total_individual_order: 10,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5239031070762',
    },
    {
      id: 5239013998634,
      order_no: '#1243',
      customer_name: 'Kelly Kemnitz',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-20T18:27:59.652Z',
      total: '366.68',
      total_line_items: 2,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5239013998634',
    },
    {
      id: 5239011901482,
      order_no: '#1242',
      customer_name: 'Kelly Kemnitz',
      individualOrderCreated: 'pending',
      fileUploaded: 'pending',
      created_on: '2024-05-20T18:25:33.825Z',
      total: '44.50',
      total_line_items: 1,
      total_individual_order: 0,
      fullfillment_status: false,
      payment_status: false,
      order_approved: false,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5239011901482',
    },
    {
      id: 5239003512874,
      order_no: '#1241',
      customer_name: 'Allondra Johnson',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-20T18:16:49.903Z',
      total: '971.77',
      total_line_items: 4,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5239003512874',
    },
    {
      id: 5238778953770,
      order_no: '#1240',
      customer_name: 'Morgan Cabral',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-20T15:08:01.379Z',
      total: '315.40',
      total_line_items: 3,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5238778953770',
    },
    {
      id: 5238749986858,
      order_no: '#1239',
      customer_name: 'Dawn Howe',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-20T14:37:02.989Z',
      total: '67.50',
      total_line_items: 2,
      total_individual_order: 3,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5238749986858',
    },
    {
      id: 5238727147562,
      order_no: '#1238',
      customer_name: 'Micah Fort',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-20T14:11:04.399Z',
      total: '214.50',
      total_line_items: 2,
      total_individual_order: 6,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5238727147562',
    },
    {
      id: 5235442647082,
      order_no: '#1237',
      customer_name: 'Jessica Buettenbach',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-17T17:52:44.200Z',
      total: '1214.70',
      total_line_items: 3,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5235442647082',
    },
    {
      id: 5234308612138,
      order_no: '#1236',
      customer_name: 'Miranda Bales',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-16T20:41:58.528Z',
      total: '304.80',
      total_line_items: 4,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5234308612138',
    },
    {
      id: 5234283577386,
      order_no: '#1235',
      customer_name: 'Jesse Meisch',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-16T20:22:06.665Z',
      total: '1989.19',
      total_line_items: 2,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: false,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5234283577386',
    },
    {
      id: 5234261950506,
      order_no: '#1234',
      customer_name: 'Jessica Buettenbach',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-16T20:05:28.711Z',
      total: '475.02',
      total_line_items: 3,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5234261950506',
    },
    {
      id: 5233950752810,
      order_no: '#1233',
      customer_name: 'Kristy Gottlob',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-16T15:44:41.678Z',
      total: '938.00',
      total_line_items: 4,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5233950752810',
    },
    {
      id: 5222819758122,
      order_no: '#1232',
      customer_name: 'Katie Moore',
      individualOrderCreated: 'done',
      fileUploaded: 'done',
      created_on: '2024-05-08T21:34:56.725Z',
      total: '347.63',
      total_line_items: 2,
      total_individual_order: 1,
      fullfillment_status: true,
      payment_status: true,
      order_approved: true,
      order_link:
        'https://admin.shopify.com/store/b2b-corporate/orders/5222819758122',
    },
  ],
};

export const itemsPerPage = 25;
